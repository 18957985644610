<template>
    <div class="pdf-wrapper">
        <PDFPage
            :page="page"
            :scale="scale"
            v-if="page !== null && loaded"
        ></PDFPage>
        <v-progress-circular
            v-else
            color="primary"
            size="125"
            indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
export const PDFPage = () => import("./PDFPage");
const pdfjsLib = require("pdfjs-dist/build/pdf");
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.js";

import { mapMutations } from "vuex";
import * as snackbarActions from "../../store/actions/snackbar";

export default {
    props: {
        source: {
            type: String,
            required: true
        }
    },

    data: () => ({
        pdf: null,
        scale: 1,
        page: null,
        loaded: false,
        currentPageNumber: 1
    }),

    components: {
        PDFPage
    },

    watch: {
        pdf(pdf) {
            // console.log(pdf);
            this.getPage(this.currentPageNumber);
        }
    },

    methods: {
        ...mapMutations({
            showError: snackbarActions.SNACKBAR_SHOW
        }),

        async fetchPDF() {
            pdfjsLib.GlobalWorkerOptions.workerPort = new pdfjsWorker();
            const pdfLoadingTask = await pdfjsLib.getDocument({
                url: this.source,
                verbosity: 5
            });
            /*                 console.log(pdfLoadingTask);
                pdfLoadingTask.onProgress = ({ loaded, total }) => {
                    console.log(loaded, total);
                }; */

            pdfLoadingTask.promise
                .then(
                    pdfDocument => {
                        this.pdf = pdfDocument;
                    },
                    exception => {
                        this.showError(exception);
                    }
                )
                .catch(error => {
                    this.showError(error);
                });
        },
        getPage(pageNumber) {
            if (this.page !== null) {
                this.page = null;
            }
            this.loaded = false;
            this.pdf
                .getPage(pageNumber)
                .then(page => {
                    try {
                        // console.log(page.getViewport({ scale: 1 }));
                        const pixelRatio = window.devicePixelRatio || 1;
                        this.scale = (pixelRatio * this.$el.clientHeight) / page.getViewport({ scale: 1 }).height;
                        this.page = page;
                        this.loaded = true;
                        // console.log(this.scale, this.$el.clientHeight)
                        // this.scale = 0.1
                    } catch (err) {
                        this.showError(err);
                    }
                })
                .catch(err => {
                    this.showError(err);
                });
        }
    },

    beforeDestroy() {
        if (this.page !== null) {
            this.page._destroy();
        }
    },

    created() {
        console.log("sono il pdf", this.source);
        this.fetchPDF();
    }
};
</script>

<style scoped>
.pdf-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
</style>
